import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io, Socket } from "socket.io-client";
import { Router } from '@angular/router';
import { SimultaneousSessionService } from './simultaneous-session.service';
import { User } from '../models/user';
import { NbThemeService } from '@nebular/theme';
import { AuditCameraView } from '../models/audit-camera-view';
import { ProfileType } from '../enums/profile-type';
import { BehaviorSubject, take } from 'rxjs';
import { NotificationService } from './notification.service';
import GetUserInfo from '../utils/get-user-info';
import { ToastService } from 'ngx-french-toast';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  randomId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private socketLogin: Socket;
  private socketBlockUser: Socket;
  private socketImportData: Socket;
  private socketNotification: Socket;
  private socketAudit: Socket;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private simultaneousSessionService: SimultaneousSessionService,
    private themeService: NbThemeService,
    private toastService: ToastService,
  ) { }

  login(): void {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!user) return;

    const {
      _id: userId,
      name,
      email,
      profileType
    }: User = user;

    this.socketLogin = io(`${environment.socketApi}/login`, { timeout: 1800000, transports: ['websocket'] });
    this.socketLogin.on('connect', () => {
      const info = GetUserInfo.execute();
      const loginIP = sessionStorage.getItem('ipAddress') || '';
      this.socketLogin.emit('login', { userId, name, email, profileType, info, loginIP });
    })
    this.socketLogin.on('message', (response: { title: string; text: string }) => {
      if (response.title === 'Aviso de logout') {
        this.close();
        const sendData = () => {
          this.simultaneousSessionService.hasSimultaneousSession.next(true);
          this.simultaneousSessionService.message.next(response);
        }
        const promise = new Promise((resolve, reject) => {
          resolve(sendData());
        })

        promise.then(() => {
          sessionStorage.removeItem('tkjwt');
          sessionStorage.removeItem('currentUser');
          sessionStorage.removeItem('theme');
          sessionStorage.removeItem('menu-state');
          sessionStorage.removeItem('authorizations');
          sessionStorage.removeItem('cameraPanelData');
          sessionStorage.removeItem('oldSocketId');
          sessionStorage.removeItem('providerRecording');
          sessionStorage.removeItem('randomId');
          sessionStorage.removeItem('providerAuths');
          sessionStorage.removeItem('ipAddress');
          this.themeService.changeTheme('default');
          this.router.navigate(['/authentication/login']);
        })
      }
    });

    this.socketLogin.on('currentSocketId', ({ socketId }) => {
      sessionStorage.setItem('oldSocketId', socketId);
    })

    if (user.profileType === ProfileType.Company || user.profileType === ProfileType.Provider ) {
      this.socketLogin.on('sendToastNotification', (e) => {
        if (e.status === 'success') {
          this.toastService.success({
            title: e.title,
            content: e.message
          });
        }
        if (e.status === 'error') {
          this.toastService.danger({
            title: e.title,
            content: e.message
          });
        }
        this.handleNotification();
      })
    }

    if (user.profileType !== ProfileType.Company) {
      this.socketLogin.on('blockDeletedUser', (e) => {
       this.router.navigate([`not-authorized/${e.messageType}`]);
      })
      this.socketAudit = io(`${environment.socketApi}/audit`, { timeout: 1800000, transports: ['websocket'] });
    }

    if (profileType === ProfileType.Customer) {
      this.socketLogin.on('blockSubscriber', (e) => {
        this.router.navigate([`not-authorized/${e.messageType}`]);
      })
    }

    if (profileType === ProfileType.Provider || profileType === ProfileType.Resale) {
      this.socketLogin.on('blockProvider', (e) => {
        this.router.navigate([`not-authorized/${e.messageType}`]);
      })
    }
  }

  handleNotification(): void {
    this.notificationService.hasNew().pipe(take(1)).subscribe({
      next: (hasNotification) => {
        this.notificationService.hasNotification.next(hasNotification);
      },
      error: (err) => {
        // this.toastService.danger({
        //   content: err?.error?.message ?? 'Não foi possível checar notificações.',
        //   title: 'Notificações',
        // });
      }
    })
  }

  sendAuditEvent(data: AuditCameraView): void {
    this.socketAudit.emit('auditCameras', {
      _id_user: data._id_user,
      _id_provider: data._id_provider,
      randomId: data.eventType === 'end' ? data.randomId : null,
      cameras: data.cameras,
      eventType: data.eventType,
      userName: data.userName,
      userEmail: data.userEmail,
      accessType: data.accessType,
      ip: data.ip,
      isRecordingView: data.isRecordingView,
      start: data.start,
      end: data.end,
      type: data.type,
      isCommunity: data.isCommunity ?? false
    }, (randomId) => {
      this.randomId.next(randomId);
    });
  }

  notification(): void {
    const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!user) return;
    this.socketNotification = io(`${environment.socketApi}/notifications`, { timeout: 1800000, transports: ['websocket'] });
    this.socketNotification.emit('listeningNotifications', { userId: user._id, connectionFrom: 'client' });
    this.socketNotification.on('newNotification', () => {
      this.notificationService.hasNotification.next(true);
    });
  }

  blockUser(): void {
    this.socketLogin.on('blockUsers', (e) => {
      this.router.navigate([`not-authorized/${e.messageType}`]);
    })
  }

  close(): void {
    if (this.socketBlockUser) this.socketBlockUser.disconnect();
    if (this.socketImportData) this.socketImportData.disconnect();
    if (this.socketLogin) this.socketLogin.disconnect();
    if (this.socketNotification) this.socketNotification.disconnect();
    if (this.socketAudit) this.socketAudit.disconnect();
  }

  startSocketLogin() {
    this.socketAudit = io(`${environment.socketApi}/audit`, { timeout: 1800000, transports: ['websocket'] });
    this.socketLogin = io(`${environment.socketApi}/login`, { timeout: 1800000, transports: ['websocket'] });
    this.socketLogin.on('currentSocketId', ({ socketId }) => {
      sessionStorage.setItem('oldSocketId', socketId);
    })
  }
}

interface Login {
  userId: string;
  name: string;
  email: string;
}
