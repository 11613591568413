import { ToastService } from 'ngx-french-toast';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NbDialogService, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbPosition, NbSidebarService, NbThemeService } from '@nebular/theme';
import { mergeMap, catchError, filter, map, takeUntil, Subject, EMPTY, of } from 'rxjs';
import { User } from 'src/app/shared/models/user';
import { WhiteLabel } from 'src/app/shared/models/white-label';
import { SignOutService } from 'src/app/modules/authentication/services/sign-out.service';
import { ProfileAuthorizationsService } from 'src/app/shared/services/profile-authorizations.service';
import { Router } from '@angular/router';
import { RecalculateTableService } from 'src/app/shared/services/recalculate-table.service';
import { AboutComponent } from 'src/app/shared/components/about/about.component';
import { AboutData, AboutService } from 'src/app/shared/services/about.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ChangeUserPhotoComponent } from 'src/app/shared/components/change-user-photo/change-user-photo.component';
import { GenerateImageUrl } from 'src/app/shared/utils/generate-image-url';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  userPictureOnly = false;
  @Input() user: User;
  @Input() whiteLabel: WhiteLabel;
  @Input() hideMenu: boolean;
  themes = [
    { value: 'default', name: 'Padrão' },
    { value: 'dark', name: 'Escuro' },
    { value: 'cosmic', name: 'Cósmico' }
  ];
  currentTheme = 'default';
  userMenu: NbMenuItem[] = [];
  hasNotification: boolean;
  aboutData: AboutData;
  loading: boolean;
  themeSelectorMenu: NbMenuItem[] = [];
  tooltipPlacement: NbPosition = NbPosition.BOTTOM;
  userPhotoUrl: string = null;
  showUserPhoto: boolean = true;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private menuService: NbMenuService,
    private breakpointService: NbMediaBreakpointsService,
    private signOutService: SignOutService,
    private profileAuthorizationsService: ProfileAuthorizationsService,
    private router: Router,
    private recalculateTable: RecalculateTableService,
    private notificationService: NotificationService,
    private dialogService: NbDialogService,
    private aboutDataService: AboutService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    const theme = localStorage.getItem('theme');
    if (theme) this.currentTheme = theme;
    this.changeTheme(this.currentTheme);
    this.userPicture();
    this.getTopMenu();
    this.handleSidebarMenu();
    this.handleNotification();
    this.getNotificationStatus();
    this.getThemeSelectorMenu();
    this.listenForMenuClicks();
    this.userPhotoUrl = GenerateImageUrl.execute(this.user?.photoUrl);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openWindow() {
    this.dialogService.open(AboutComponent, {
      context: {
        aboutData: this.aboutData
      },
      closeOnBackdropClick: true,
      closeOnEsc: true
    });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    localStorage.setItem('theme', themeName);
  }

  userPicture() {
    const { xl } = this.breakpointService.getBreakpointsMap();

    this.themeService.onMediaQueryChange()
      .pipe(
        takeUntil(this.destroy$),
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.userPictureOnly = isLessThanXl
      });
  }

  getTopMenu() {
    this.userMenu = this.profileAuthorizationsService.getTopMenu();
    this.userMenu.unshift({
      title: 'Alterar foto'
    })
  }

  getThemeSelectorMenu(): void {
    this.themes.forEach(theme => {
      this.themeSelectorMenu.push({
        title: theme.name,
        data: theme.value,

      })
    })
  }

  toggleSidebar() {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.recalculateTable.recalculateTrigger.next(true);

    return false;
  }

  async handleSidebarMenu() {
    const state = await sessionStorage.getItem('menu-state');

    if (state === 'expanded') {
      this.sidebarService.expand('menu-sidebar');
      return;
    }
    if (state === 'compacted') {
      this.sidebarService.compact('menu-sidebar');
      return;
    }
  }

  navigateToNotification(): void {
    this.router.navigate(['/notifications']);
  }

  handleNotification(): void {
    this.notificationService.hasNew().pipe(takeUntil(this.destroy$)).subscribe({
      next: (hasNotification) => {
        this.notificationService.hasNotification.next(hasNotification);
      },
      error: (err) => {
        // this.toastService.danger({
        //   content: err?.error?.message ?? 'Não foi possível checar notificações.',
        //   title: 'Notificações',
        // });
      }
    })
  }

  getNotificationStatus() {
    this.notificationService.hasNotification.pipe(takeUntil(this.destroy$)).subscribe({
      next: (hasNotification) => {
        this.hasNotification = hasNotification;
      }
    })
  }

  listenForMenuClicks(): void {
    this.menuService
      .onItemClick()
      .pipe(
        takeUntil(this.destroy$),
        filter(({ tag }) => tag === 'top-menu' || tag === 'theme-selector'),
        mergeMap((res) => {
          this.loading = true;
          return (res.tag === 'top-menu' && res.item.title === 'Sobre')
            ? this.aboutDataService.getAboutData().pipe(
                catchError((err) => {
                  this.loading = false;
                  this.toastService.danger({
                    title: 'Sobre',
                    content:
                      err?.error?.message ??
                      'Não foi possível buscar os dados.',
                  });
                  return EMPTY;
                }),
                map((aboutData) => {
                  this.aboutData = aboutData;
                  return res;
                })
              )
            : of(res);
        })
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res.tag === 'theme-selector') {
            this.changeTheme(res.item.data);
          }
          if (res.tag === 'top-menu' && res.item.title === 'Sobre') {
            if (this.aboutData) this.openWindow();
          }
          if (res.tag === 'top-menu' && res.item.title === 'Sair') {
            this.themeService.changeTheme('default');
            this.signOutService.signOut();
          }
          if (res.tag === 'top-menu' && res.item.title === 'Alterar foto') {
            this.openPhotoDialog();
          }
        },
      });
  }

  openPhotoDialog(): void {
    this.user.photoUrl = GenerateImageUrl.execute(this.user.photoUrl);
    const dialog = this.dialogService.open(ChangeUserPhotoComponent, {
      context: {
        user: this.user
      }
    });
    dialog.onClose.pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        if (res) {
          this.userPhotoUrl = null;
          setTimeout(() => {
            const user: User = JSON.parse(sessionStorage.getItem('currentUser'));
            user.photoUrl = GenerateImageUrl.execute(user?.photoUrl);
            this.user = user;
            this.userPhotoUrl = this.user.photoUrl;
          }, 0);
        }
      }
    })
  }

  generateImageUrl(photoUrl: string): string {
    if (!photoUrl) return null;
    const timestamp = new Date().getTime().toString();
    return photoUrl + '?' + timestamp;
  }
}