import { ProfileType } from './../enums/profile-type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { VideoSnippetExportEventInput, VideoSnippetsInput, VideoSnippetResult } from '../models/video-snippet-export';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api = `${environment.api}/main`;
  apiPublic = `${environment.api}/main-public`;

  constructor(
    private http: HttpClient
  ) { }

  getUserData(): Observable<User> {
    const url = `${this.api}/v2/usergetdata`;

    return this.http.get<User>(url, this.getHeaders());
  }

  update(user: UserUpdate): Observable<any> {
    const url = `${this.api}/v1/users/${user._id}`;

    return this.http.put<any>(url, user, this.getHeaders());
  }

  massUpdate(users: User[], _id_profile: string): Observable<any> {
    const url = `${this.api}/v1/users/update-batch`;
    const data = {
      _id_profile,
      users,
    };

    return this.http.post<any>(url, data, this.getHeaders());
  }

  delete(userId: string): Observable<any> {
    const url = `${this.api}/v1/users/${userId}`;

    return this.http.delete<any>(url, this.getHeaders());
  }

  reinvite(data: Reinvite): Observable<any> {
    const url = `${this.api}/v1/auth/reinvite`;

    return this.http.post<any>(url, data, this.getHeaders());
  }

  authorizations(id: string): Observable<any> {
    const url = `${this.api}/v1/users/${id}/authorizations`;

    return this.http.get<any>(url, this.getHeaders());
  }

  invite(user: User): Observable<any> {
    const url = `${this.api}/v1/auth/invite`;

    return this.http.post<any>(url, user, this.getHeaders());
  }

  resetPassword(email: string, _id_provider: string): Observable<any> {
    const url = `${this.apiPublic}/v1/auth/recover-password`;

    return this.http.post<any>(url, { email, _id_provider }, this.getHeadersWithoutTk());
  }

  updatePassword(data: UpdatePassword): Observable<any> {
    const url = `${this.apiPublic}/v1/auth/recover-password`;

    return this.http.put<any>(url, data, this.getHeadersWithoutTk());
  }

  changePassword(data: ChangePass): Observable<any> {
    const url = `${this.api}/v1/users/${data._id}/change-pass`;

    return this.http.post<any>(url, data, this.getHeaders());
  }

  changeTemporaryPassword(data: TemporaryPassword): Observable<any> {
    const url = `${this.api}/v1/auth/change-temporary-password`;

    return this.http.post<any>(url, data, this.getHeaders());
  }

  confirmEmail(token: string): Observable<any> {
    const url = `${this.apiPublic}/v1/auth/confirm-email`;
    return this.http.put<any>(url, { tokenInvite: token }, this.getHeadersWithoutTk());
  }

  getByUserProviderPartnerSubscriber(version: string = 'v1', data: UserPartnerSubscribers): Observable<User[]> {
    let { _id_provider, _id_partner, customers, profileType, _id_camera } = data;
    const params: any = {
      _id_provider,
      _id_partner,
      _id_subscriber: JSON.stringify(customers)
    }

    if (profileType) {
      params.profileType = profileType;
    }

    if (_id_camera) {
      params._id_camera = _id_camera;
    }

    const { headers } = this.getHeaders();

    let url = `${this.api}/${version}/list-users`;
    return this.http.get<User[]>(url, { headers, params });
  }

  saveExportEvent(_id_user: string, data: VideoSnippetExportEventInput): Observable<any> {
    const url = `${this.api}/v1/users/${_id_user}/exported-videos`;
    return this.http.post(url, data, this.getHeaders());
  }

  getExportEvents(_id_user: string, data: VideoSnippetsInput): Observable<VideoSnippetResult> {
    const url = `${this.api}/v2/users/${_id_user}/exported-videos`;
    const { camerasIds, endDate, startDate, limit, skip, sortField, sortOrder } = data;
    const params: any = {
      startDate,
      endDate,
      camerasIds: JSON.stringify(camerasIds),
      limit,
      skip
    };
    if (sortField) params.sortField = sortField;
    if (sortOrder) params.sortOrder = sortOrder;
    const { headers } = this.getHeaders();
    return this.http.get<VideoSnippetResult>(url, { headers, params });
  }

  changePhoto(_id_user: string, photo: string): Observable<{ photoUrl: string }> {
    const url = `${this.api}/v1/users/${_id_user}/change-photo`;
    const { headers } = this.getHeaders();
    return this.http.put<{ photoUrl: string }>(url, { photo }, { headers });
  }

  recoverAccount(token: string): Observable<void> {
    const url = `${this.apiPublic}/v1/users/${token}/unblock`;
    const { headers } = this.getHeadersWithoutTk();
    return this.http.put<any>(url, {}, { headers });
  }

  checkUserCommunityAccess(): Observable<{ hasCommunityAccess: boolean }> {
    const url = `${this.api}/v1/users/community-access`;
    return this.http.get<{ hasCommunityAccess: boolean}>(url, this.getHeaders());
  }

  private getHeaders(): { headers: HttpHeaders } {
    const tkjwt = sessionStorage.getItem('tkjwt');

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${tkjwt}`,
        'x-access-token': tkjwt
      })
    }
  }

  private getHeadersWithoutTk(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
    };
  }
}

interface UserPartnerSubscriber {
  id_provider: string;
  id_partner: string;
  id_subscriber: string;
}

export interface UserPartnerSubscribers {
  _id_provider: string;
  _id_partner: string;
  customers: string[];
  profileType?: ProfileType;
  _id_camera?: string;
}

export interface UserUpdate {
  username: string;
  name: string;
  email: string;
  _id: string;
  _id_provider: string;
  _id_partner: string;
  _id_subscriber: string;
  _id_profile?: string;
}

interface ChangePass {
  _id: string;
  currentPassword: string;
  newPassword: string;
}

interface Reinvite {
  _id_user: string;
  _id_provider: string;
}

interface UpdatePassword {
  newPassword: string;
  confirmPassword: string;
  tokenRecover: string;
}

interface TemporaryPassword {
  new_password: string;
  confirm_password: string;
  current_password: string;
}
