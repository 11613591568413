import { environment } from './../environments/environment';
import { GeneralInterceptor } from './shared/interceptors/general.interceptor';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

import { NbThemeModule } from '@nebular/theme';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullComponent } from './layouts/full/full.component';
import { HeaderComponent } from './layouts/full/header/header.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { CustomNebularThemeModule } from './shared/customs/custom-nebular-theme.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from './layouts/block-template/block-template.component';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { SimultaneousSessionWarningComponent } from './layouts/simultaneous-session-warning/simultaneous-session-warning.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeleteConfirmationDialogComponent } from './shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FrenchToastModule, ToastPosition } from 'ngx-french-toast';
import { AngularFireModule  } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NotificationResetWalkthroughComponent } from './shared/components/notification-reset-walkthrough/notification-reset-walkthrough.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

registerLocaleData(ptBr)
@NgModule({
	declarations: [
		AppComponent,
		FullComponent,
		HeaderComponent,
		BlankComponent,
		BlockTemplateComponent,
		NotFoundComponent,
		SimultaneousSessionWarningComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		AppRoutingModule,
		NbThemeModule.forRoot({ name: 'default' }),
		NbEvaIconsModule,
		CustomNebularThemeModule,
		BlockUIModule.forRoot({
			template: BlockTemplateComponent
		}),
		GoogleMapsModule,
		FormsModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.sw,
			registrationStrategy: 'registerWhenStable:30000'
		}),
		DeleteConfirmationDialogComponent,
		FrenchToastModule.forRoot({
			defaultDuration: 7000,
      colors: {
        danger: '#e10044',
        success: '#02d1a6',
        info: '#2915b8',
        warning: '#a869e3',
        timebar: 'linear-gradient(45deg, #ffdc00, #fe9826)',
      },
      position: ToastPosition.BOTTOM_RIGHT,
		}),
		AngularFireModule.initializeApp({
			apiKey: "AIzaSyDJnYTBGWfgNguBSpmmmxV_RmywQRiEHMA",
			authDomain: "d-guard-cloud-20728.firebaseapp.com",
			projectId: "d-guard-cloud-20728",
			storageBucket: "d-guard-cloud-20728.appspot.com",
			messagingSenderId: "715193105819",
			appId: "1:715193105819:web:f959cd8e128cdcadf7753e",
			measurementId: "G-ZPS9213GC6"
		}),
		AngularFireMessagingModule,
		NotificationResetWalkthroughComponent,
		NgxSliderModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: HTTP_INTERCEPTORS, useClass: GeneralInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
